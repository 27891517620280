import React from 'react';

import PRODUCTS from './ProductDataTypes';
import {getSiteConfig} from '../config';

const SITE_CONFIG = getSiteConfig();


const generateResourceId = (id) => {
    return PRODUCTS.ENDOSEE_ADVANCE+"_"+id;
};


const endoseeAdvanceData =
{
    id: PRODUCTS.ENDOSEE_ADVANCE,
    alt:"Endosee Advance System",
    title:(<React.Fragment>Endosee<sup>&reg;</sup> Advance System</React.Fragment>),
    productImages: [
            require('../assets/images/endosee/endosee-product-img.png'),
            require('../assets/images/endosee/endosee-product-img@2x.png'),
            require('../assets/images/endosee/endosee-product-img@3x.png')
        ],
    description:'',
    videoData:{
        videoSets:[
            {
                isChapterBased:true,
                videos:[
                    {
                        resourceId: generateResourceId("vs1v1"),
                        title:"Chapter 1",
                        subTitle: "System Overview & General Operation",
                        description:(<React.Fragment>Brief overview including components, working channel instruments, assembly, video and still capture.</React.Fragment>),
                        wistiaId:"otpktyyo3l",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    },
                    {
                        resourceId:generateResourceId("vs1v2"),
                        title:"Chapter 2",
                        subTitle: "Pre-Procedure Setup",
                        description:(<React.Fragment>List of supplies needed for procedure, including optional supplies. Patient pretreatment options.</React.Fragment>),
                        wistiaId:"jc082u97iu",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    },
                    {
                        resourceId:generateResourceId("vs1v3"),
                        title:"Chapter 3",
                        subTitle: "Patient Examination Procedure",
                        description:(<React.Fragment>Entering Patient ID, system assembly, direct visualization procedure, performing optional therapeutic and diagnostic procedures, post-procedure steps including cleaning, recharging.</React.Fragment>),
                        wistiaId:"qneqkw81y3",
                        wistiaConfig:{
                            padding:"56.25% 0 0 0"
                        }
                    }
                ]

            }
        ]
    },
    documentData:{
        documents:[
            {
                resourceId: generateResourceId("d1"),
                title:"Endosee Advance System User Manual",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/Endosee-Advance-Instructions-for-Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/thumbnail_endosee-for-use.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_endosee-for-use@2x.jpg"),
                    require("../assets/images/doc-thumbnails/thumbnail_endosee-for-use@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d2"),
                title:"Endosee Advance Office Checklist",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/endosee-advance/Endosee Advance Office Checklist.pdf",
                thumbnails:[
                    require("../assets/images/endosee/doc-thumbnails/Endosee-Advance-Office-Checklist-125x155.jpg"),
                    require("../assets/images/endosee/doc-thumbnails/Endosee-Advance-Office-Checklist-125x155@2x.jpg"),
                    require("../assets/images/endosee/doc-thumbnails/Endosee-Advance-Office-Checklist-125x155@3x.jpg")
                ]
            },

            // {
            //     resourceId: generateResourceId("d3"),
            //     title:"Endosee Advance Reimbursement Guide – 2022",
            //     description: null,
            //     url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/Endosee-Reimbursement-Guide-2022.pdf",
            //     thumbnails:[
            //         require("../assets/images/doc-thumbnails/thumbnail_endosee-reimburse.jpg"),
            //         require("../assets/images/doc-thumbnails/thumbnail_endosee-reimburse@2x.jpg"),
            //         require("../assets/images/doc-thumbnails/thumbnail_endosee-reimburse@3x.jpg")
            //     ]
            // },
            {
                resourceId: generateResourceId("d4"),
                title:"Endosee Advance Stainless Steel Instruments Instructions for Use",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/Endosee-Advance-Stainless-Steel-Instruments-Instructions-for-Use.pdf",
                thumbnails:[
                    require("../assets/images/doc-thumbnails/endosee-ifu-document.jpg"),
                    require("../assets/images/doc-thumbnails/endosee-ifu-document@2x.jpg"),
                    require("../assets/images/doc-thumbnails/endosee-ifu-document@3x.jpg")
                ]
            },


            {
                resourceId: generateResourceId("d5"),
                title:"Endosee Advance Accessories Sell Sheet",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/endosee-advance/Endosee Advance Accessories Sell Sheet.pdf",
                thumbnails:[
                    require("../assets/images/endosee/doc-thumbnails/Endosee-Advance-Accessories-Checklist-125x155.jpg"),
                    require("../assets/images/endosee/doc-thumbnails/Endosee-Advance-Accessories-Checklist-125x155@2x.jpg"),
                    require("../assets/images/endosee/doc-thumbnails/Endosee-Advance-Accessories-Checklist-125x155@3x.jpg")
                ]
            },
            {
                resourceId: generateResourceId("d6"),
                title:"Endosee Advance HCP Brochure",
                description: null,
                url:SITE_CONFIG.DOC_SITE_DEFAULT_URL+"/docs/endosee-advance/Endosee Advance HCP Brochure.pdf",
                thumbnails:[
                    require("../assets/images/endosee/doc-thumbnails/Endosee-Advance-HCP-Brochure-125x155.jpg"),
                    require("../assets/images/endosee/doc-thumbnails/Endosee-Advance-HCP-Brochure-125x155@2x.jpg"),
                    require("../assets/images/endosee/doc-thumbnails/Endosee-Advance-HCP-Brochure-125x155@3x.jpg")
                ]
            }
        ]
    },
    externalProductId:"ES9000"
};


export default endoseeAdvanceData;








